@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply antialiased bg-background text-foreground;
  }

  .chat-input-context {
    @apply pl-4 my-1 mx-0 relative after:content-[''] after:block after:absolute after:left-0 after:top-0 after:bottom-0 after:w-1 after:bg-gray-300 after:rounded-md;
  }
}

@layer utilities {
  .border-beam-mask {
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 1px solid transparent;
    mask-clip: padding-box, border-box;
    -webkit-mask-clip: padding-box, border-box;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    mask-image: linear-gradient(transparent, transparent),
      linear-gradient(#000, #000);
    -webkit-mask-image: linear-gradient(transparent, transparent),
      linear-gradient(#000, #000);
  }
}

@layer utilities {
  /* Field Sizing */
  .field-sizing-fixed {
    field-sizing: fixed;
  }

  .field-sizing-content {
    field-sizing: content;
  }

  .checkerboard {
    background: repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 20px 20px
  }
}
